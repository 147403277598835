import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from 'src/app/services/langauge.service';
import { AppCoreService } from 'src/app/services/app-core.service';
import { AppTokenService } from 'src/app/services/app-token.service';
import { UserData } from 'src/app/models/user.data-object';
import { LoginService } from 'src/app/services/login.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  logoURL = 'assets/images/logo-cloud.png';
  loggedInAs = 'Client';
  serviceCenters = [];
  profileImageUrl: string = 'assets/images/users/avatar-default.jpg';

  tooltipVisible: boolean = false;
  tooltipText: string = '';
  tooltipX: number = 0;
  tooltipY: number = 0;
  creditDetails = { limit: 0, balance: 0, used: 0 };

  showSearch = false;
  permConfig = {
    isViewAllowed: false,
    isAddAllowed: false,
    isEditAllowed: false,
    isDeleteAllowed: false
  };

  constructor(
    @Inject(DOCUMENT) private document: any,
    private appCoreService: AppCoreService,
    private appTokenService: AppTokenService,
    private loginService: LoginService,
    private router: Router,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
  ) {

  }

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Hindi', flag: 'assets/images/flags/india.png', lang: 'hi' },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  userData: UserData;
  selectedSCId: number;

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    this.userData = this.appCoreService.GetUserData();
    this.serviceCenters = this.userData.servicecenters;
    this.appCoreService.SelectedServiceCenter().subscribe((val) => {
      this.selectedSCId = val;
    });

    // console.log(this.userData);
    if (this.userData.accessType !== 'System') {
      this.loggedInAs = this.userData.accessType;
    }

    // this.userData.metaInfo.logoUrl = 'https://next.busisoft.in/Content/Assets/img/logo1.png';
    if (this.userData.metaInfo.logoUrl) {
      this.logoURL = this.userData.metaInfo.logoUrl;
    }

    if (this.userData?.metaInfo?.profileimageurl) {
      this.profileImageUrl = this.userData?.metaInfo?.profileimageurl;
    }

    // Subscribe to the BehaviorSubject to listen for updates
    this.appCoreService.getCurrentCreditDetails().subscribe((details) => {
      this.creditDetails = details;
    });

    this.checkPermission();
    if (this.permConfig.isViewAllowed) {
      this.showSearch = true;
    }
  }

  checkPermission(): any {
    const userData = this.appCoreService.GetUserData();
    const permission = userData.config.filter(x => x.categoryname === 'Tracking');
    if (permission.length) {
      this.permConfig.isViewAllowed = permission[0].view;
      this.permConfig.isAddAllowed = permission[0].add;
      this.permConfig.isEditAllowed = permission[0].edit;
      this.permConfig.isDeleteAllowed = permission[0].delete;
    }
  }

  // Show tooltip on hover
  showTooltip(event: MouseEvent, text: string) {
    this.tooltipVisible = true;
    this.tooltipText = text;
    this.tooltipX = event.pageX + 10;
    this.tooltipY = event.pageY + 10;
  }

  // Hide tooltip when not hovering
  hideTooltip(event: MouseEvent) {
    this.tooltipVisible = false;
    this.tooltipText = '';
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    const requestObj = {
      id: this.userData?.userId,
      email: this.userData.emailId,
      clientid: this.userData.clientId,
      sid: this.userData.sessionid,
      deviceid: 1
    };
    this.loginService.LogoutUser(requestObj).subscribe({});
    this.appCoreService.removeAllFromLS();
    this.appCoreService.RemoveUserData();
    this.appTokenService.removeToken();
    this.router.navigate(['/account/login']);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  onServiceCenterChange(evt) {
    if (evt) {
      let selectedValue = evt.target.value;
      // console.log('onchange', selectedValue);
      this.appCoreService.SetServiceCenter(selectedValue);
    }
  } 

  search(awbInput: HTMLInputElement) {
    let awbnumber = awbInput.value.trim();   
    if (!awbnumber) {
      this.appCoreService.showMessage("Enter valid AWB number", 'Warning', 'warning');
      return;
    }
  
    this.router.navigate(['/operation/awb/track', awbInput.value]).then(() => awbInput.value = '');
  }
  
  searchMobile(event: Event, mobileAwbInput: HTMLInputElement, searchDropdown: NgbDropdown) {
    event.preventDefault();
    this.search(mobileAwbInput);
    searchDropdown.close();
  }
  

  
}
