export interface MenuItem {
    id?: number;
    name?: string;
    categoryname?: string;
    label?: string;
    icon?: string;
    link?: string;
    subItems?: any;
    isTitle?: boolean;
    badge?: any;
    parentId?: number;
    isLayout?: boolean;
    visible?: boolean;
}

export const MENU: MenuItem[] = [
    {
        id: 1,
        name: 'Dashboard',
        categoryname: "StandardDashboard",
        label: 'MENUITEMS.DASHBOARD.TEXT',
        icon: 'bxs-bar-chart-alt-2',
        link: '/dashboard',
    },
    {
        id: 2,
        name: 'MyNetwork',
        categoryname: "MyNetwork",
        label: 'MENUITEMS.MYNETWORK.TEXT',
        icon: 'bx-globe',
        subItems: [
            {
                id: 2.1,
                name: 'HeadOffice',
                categoryname: "HeadOffice",
                label: 'MENUITEMS.MYNETWORK.LIST.HEADOFFICE',
                link: '/network/headoffices',
                parentId: 2
            },
            {
                id: 2.2,
                name: 'ZonalOffice',
                categoryname: "ZonalOffice",
                label: 'MENUITEMS.MYNETWORK.LIST.ZONALOFFICE',
                link: '/network/zonaloffices',
                parentId: 2
            },
            {
                id: 2.3,
                name: 'RegionalOffice',
                categoryname: "RegionalOffice",
                label: 'MENUITEMS.MYNETWORK.LIST.REGIONALOFFICE',
                link: '/network/regionaloffices',
                parentId: 2
            },
            {
                id: 2.4,
                name: 'Hub',
                categoryname: "Hub",
                label: 'MENUITEMS.MYNETWORK.LIST.HUB',
                link: '/network/hubs',
                parentId: 2
            },
            {
                id: 2.5,
                name: 'ServiceCenter',
                categoryname: "ServiceCenter",
                label: 'MENUITEMS.MYNETWORK.LIST.SERVICECENTER',
                link: '/network/servicecenters',
                parentId: 2
            }
        ]
    },
    {
        id: 3,
        name: 'Setup',
        categoryname: "Setup",
        label: 'MENUITEMS.SETUP.TEXT',
        icon: 'bx-cog',
        subItems: [
            {
                id: 3.1,
                name: 'Companies',
                categoryname: "Companies",
                label: 'MENUITEMS.SETUP.LIST.COMPANIES',
                link: '/setup/companies',
                parentId: 3,
            },
            {
                id: 3.2,
                name: 'Customers',
                categoryname: "Customers",
                label: 'MENUITEMS.SETUP.LIST.CUSTOMERS',
                link: '/setup/customers',
                parentId: 3,
            },
            {
                id: 3.3,
                name: 'Products',
                categoryname: "Products",
                label: 'MENUITEMS.SETUP.LIST.PRODUCTS',
                link: '/setup/products',
                parentId: 3,
            },
            {
                id: 3.4,
                name: 'Vendors',
                categoryname: "Vendors",
                label: 'MENUITEMS.SETUP.LIST.VENDORS',
                link: '/setup/vendors',
                parentId: 3,
            },
            {
                id: 3.5,
                name: 'Zones',
                categoryname: "Zones",
                label: 'MENUITEMS.SETUP.LIST.ZONES',
                link: '/setup/zones',
                parentId: 3,
            },
            {
                id: 3.6,
                name: 'General',
                categoryname: "General",
                label: 'MENUITEMS.SETUP.LIST.GENERAL.TEXT',
                parentId: 3,
                subItems: [
                    {
                        id: 3.61,
                        name: 'AddressBook',
                        categoryname: "General",
                        label: 'MENUITEMS.SETUP.LIST.GENERAL.LIST.ADDRESSBOOK',
                        link: '/setup/general/addressbook',
                        parentId: 3.6,

                    }
                ]
            },
            {
                id: 3.7,
                name: 'AssetManagement',
                categoryname: "AssetManagement",
                label: 'MENUITEMS.SETUP.LIST.ASSETMANAGEMENT',
                link: '/setup/assets',
                parentId: 3,
            },
        ]
    },
    {
        id: 4,
        name: 'SystemConfiguration',
        label: 'MENUITEMS.SYSTEMCONFIG.TEXT',
        icon: 'bx-table',
        subItems: [
            {
                id: 4.1,
                name: 'AWBStock',
                categoryname: 'AWBStock',
                label: 'MENUITEMS.SYSTEMCONFIG.LIST.AWB',
                parentId: 4,
                link: '/config/awb',
            },
            {
                id: 4.2,
                name: 'Charges',
                categoryname: 'Charges',
                label: 'MENUITEMS.SYSTEMCONFIG.LIST.CHARGES.TEXT',
                parentId: 4,
                subItems: [
                    {
                        id: 4.20,
                        name: 'Manage',
                        categoryname: 'Manage',
                        label: 'MENUITEMS.SYSTEMCONFIG.LIST.CHARGES.LIST.MANAGE',
                        link: '/config/charges/manage',
                        parentId: 4.2
                    },
                    {
                        id: 4.21,
                        name: 'Upload',
                        categoryname: 'Upload',
                        label: 'MENUITEMS.SYSTEMCONFIG.LIST.CHARGES.LIST.UPLOAD',
                        link: '/config/charges/upload',
                        parentId: 4.2
                    },
                    // {
                    //     id: 4.22,
                    //     name: 'FuelSurcharge',
                    //     label: 'MENUITEMS.SYSTEMCONFIG.LIST.CHARGES.LIST.FUELSURCHARGE',
                    //     link: '/config/charges/fuelsurcharges',
                    //     parentId: 4.2
                    // },
                    {
                        id: 4.22,
                        name: 'ODA',
                        categoryname: 'ODA',
                        label: 'MENUITEMS.SYSTEMCONFIG.LIST.CHARGES.LIST.ODA',
                        link: '/config/charges/oda',
                        parentId: 4.2
                    }

                ]
            },
            {
                id: 4.3,
                name: 'Taxes',
                categoryname: 'Taxes',
                label: 'MENUITEMS.SYSTEMCONFIG.LIST.TAXES',
                parentId: 4,
                link: '/config/taxes',
            },
            {
                id: 4.4,
                name: 'Invoices',
                categoryname: 'InvoiceSeries',
                label: 'MENUITEMS.SYSTEMCONFIG.LIST.INVOICESERIES',
                parentId: 4,
                link: '/config/invoiceseries',
            },
            {
                id: 4.5,
                name: 'Templates',
                categoryname: 'Templates',
                label: 'MENUITEMS.SYSTEMCONFIG.LIST.TEMPLATES',
                parentId: 4,
                link: '/config/templates',
            },
            {
                id: 4.6,
                name: 'Templates',
                categoryname: 'Credit Control',
                label: 'MENUITEMS.SYSTEMCONFIG.LIST.CREDITCONTROL',
                parentId: 4,
                link: '/config/creditcontrol',
            },
            {
                id: 4.7,
                name: 'TAT',
                categoryname: 'TAT',
                label: 'MENUITEMS.SYSTEMCONFIG.LIST.TAT',
                parentId: 4,
                link: '/config/tat',
            },
            {
                id: 4.8,
                name: 'DISCOUNT',
                label: 'MENUITEMS.SYSTEMCONFIG.LIST.DISCOUNT',
                parentId: 4,
                link: '/config/discount',
            },
            {
                id: 4.9,
                name: 'Vendor AWB',
                label: 'MENUITEMS.SYSTEMCONFIG.LIST.VENDORAWB',
                parentId: 4,
                link: '/config/vendorawb',
            },
            {
                id: 4.10,
                name: 'Data Import',
                label: 'MENUITEMS.SYSTEMCONFIG.LIST.DATAIMPORT',
                parentId: 4,
                link: '/config/import',
            },
        ]
    },
    {
        id: 5,
        name: 'Operations',
        label: 'MENUITEMS.OPERATIONS.TEXT',
        icon: 'bx-transfer-alt',
        subItems: [
            {
                id: 5.1,
                name: 'RateManagement',
                categoryname: 'RateManagement',
                label: 'MENUITEMS.OPERATIONS.LIST.RATEMANAGEMENT.TEXT',
                parentId: 5,
                subItems: [
                    {
                        Id: 5.11,
                        name: 'Upload',
                        categoryname: 'Upload',
                        label: 'MENUITEMS.OPERATIONS.LIST.RATEMANAGEMENT.LIST.UPLOAD',
                        link: '/operation/rates/upload',
                        parentId: 5.1
                    },
                    {
                        Id: 5.12,
                        name: 'Compare',
                        categoryname: 'Compare',
                        label: 'MENUITEMS.OPERATIONS.LIST.RATEMANAGEMENT.LIST.COMPARE',
                        link: '/operation/rates/compare',
                        parentId: 5.1
                    }
                ]
            },
            {
                id: 5.2,
                name: 'Pickups',
                categoryname: 'Pickups',
                label: 'MENUITEMS.OPERATIONS.LIST.PICKUP',
                link: '/operation/pickups',
                parentId: 5
            },
            {
                id: 5.3,
                name: 'AirWayBills',
                categoryname: 'AirWayBills',
                label: 'MENUITEMS.OPERATIONS.LIST.AWB',
                link: '/operation/awb',
                parentId: 5
            },
            {
                id: 5.4,
                name: 'Manifests',
                categoryname: 'Manifests',
                label: 'MENUITEMS.OPERATIONS.LIST.MANIFEST',
                link: '/operation/manifests',
                parentId: 5
            },
            {
                id: 5.5,
                name: 'DRSScan',
                categoryname: 'DRSScan',
                label: 'MENUITEMS.OPERATIONS.LIST.DRS',
                link: '/operation/drs',
                parentId: 5
            },

        ]
    },
    {
        id: 6,
        name: 'Invoices',
        categoryname: 'Invoices',
        label: 'MENUITEMS.INVOICES.TEXT',
        icon: 'bxs-receipt',
        subItems: [
            {
                id: 6.1,
                name: 'Sales',
                categoryname: 'SalesInvoice',
                label: 'MENUITEMS.INVOICES.LIST.SALES',
                link: '/invoices/sales',
                parentId: 6
            },
            {
                id: 6.2,
                name: 'Supplementary',
                categoryname: 'SupplementaryInvoice',
                label: 'MENUITEMS.INVOICES.LIST.SUPPLEMENTARY',
                link: '/invoices/supplementary',
                parentId: 6
            },
            {
                id: 6.2,
                name: 'VendorReco',
                categoryname: 'VendorReconciliation',
                label: 'MENUITEMS.INVOICES.LIST.VENDORRECO',
                link: '/invoices/vendorreco',
                parentId: 6
            }
        ]
    },
    {
        id: 7,
        name: 'Reports',
        categoryname: 'Reports',
        label: 'MENUITEMS.REPORTS.TEXT',
        icon: 'bxs-report',
        subItems: [
            {
                id: 7.1,
                name: 'Reports',
                categoryname: 'StandardReports',
                label: 'MENUITEMS.REPORTS.LIST.REPORTS',
                link: '/reports/reports',
                parentId: 7
            },
            {
                id: 7.2,
                name: 'CustomReports',
                categoryname: 'CustomReports',
                label: 'MENUITEMS.REPORTS.LIST.CUSTOMREPORTS',
                link: '/reports/customreports',
                parentId: 7
            }
        ]
    },
    {
        id: 8,
        name: 'AccountReceivable',
        categoryname: 'AccountReceivable',
        label: 'MENUITEMS.ACCOUNTRECEIVABLE.TEXT',
        icon: 'bxs-detail',
        subItems: [
            {
                id: 8.1,
                name: 'ReceiptEntry',
                categoryname: 'ReceiptEntry',
                label: 'MENUITEMS.ACCOUNTRECEIVABLE.LIST.RECEIPTENTRY',
                link: '/account/receiptentry',
                parentId: 8
            },
            {
                id: 8.2,
                name: 'Debit/Credit Note',
                categoryname: 'Debit/Credit Note',
                label: 'MENUITEMS.ACCOUNTRECEIVABLE.LIST.DEBITNOTECREDITNOTE',
                link: '/account/debitnotecreditnote',
                parentId: 8
            },
            {
                id: 8.3,
                name: 'Expense',
                categoryname: 'Expense',
                label: 'MENUITEMS.ACCOUNTRECEIVABLE.LIST.EXPENSE',
                icon: 'bxs-report',
                link: '/account/expense',
                parentId: 8
            }
        ]
    },
    {
        id: 9,
        name: 'UserManagement',
        categoryname: 'UserManagement',
        label: 'MENUITEMS.USERMANAGEMENT.TEXT',
        icon: 'bxs-user-detail',
        subItems: [
            {
                id: 9.1,
                name: 'Roles',
                categoryname: 'Roles',
                label: 'MENUITEMS.USERMANAGEMENT.LIST.ROLES',
                link: '/iam/roles',
                parentId: 9
            },
            {
                id: 9.2,
                name: 'Users',
                categoryname: 'Users',
                label: 'MENUITEMS.USERMANAGEMENT.LIST.USERS',
                link: '/iam/users',
                parentId: 9
            },
        ]
    }
    // {
    //     id: 4,
    //     label: 'System Configuration',
    //     icon: 'bx-table',
    //     subItems: [
    //         {
    //             id: 4.1,
    //             label: 'MENUITEMS.MASTERS.LIST.LEVEL1.SALES',
    //             parentId: 4,
    //             subItems: [
    //                 {
    //                     id: 4.11,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.SALES-L2.ZONE',
    //                     link: '/sales/zone',
    //                     parentId: 4.1,
    //                 },
    //                 {
    //                     id: 4.12,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.SALES-L2.DESTINATION',
    //                     link: '/sales/destination',
    //                     parentId: 3,
    //                 },
    //                 {
    //                     id: 6.2,
    //                     label: 'Financial',
    //                     link: '/sales/financial',
    //                     parentId: 3,
    //                 },
    //                 {
    //                     id: 6.3,
    //                     label: 'Bank',
    //                     link: '/sales/bank',
    //                     parentId: 3,
    //                 },
    //                 {
    //                     id: 7,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.SALES-L2.SALESEXECUTIVE',
    //                     link: '/sales/salesexecutive',
    //                     parentId: 3,
    //                 },
    //                 {
    //                     id: 8,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.SALES-L2.INDUSTRY',
    //                     link: '/sales/industry',
    //                     parentId: 3,
    //                 },
    //                 {
    //                     id: 9,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.SALES-L2.FLIGHT',
    //                     link: '/sales/flight',
    //                     parentId: 3,
    //                 },
    //                 {
    //                     id: 10,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.SALES-L2.CURRENCY',
    //                     link: '/sales/currency',
    //                     parentId: 3,
    //                 },
    //                 {
    //                     id: 11,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.SALES-L2.CURRENCYRATE',
    //                     link: '/sales/currencyrate',
    //                     parentId: 3,
    //                 },
    //                 {
    //                     id: 12,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.SALES-L2.CONTENT',
    //                     link: '/sales/content',
    //                     parentId: 3,
    //                 },
    //                 {
    //                     id: 13,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.SALES-L2.INSTRUCTION',
    //                     link: '/sales/instruction',
    //                     parentId: 3,
    //                 },
    //                 {
    //                     id: 14,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.SALES-L2.LOCAL',
    //                     parentId: 3,
    //                 },
    //                 {
    //                     id: 15,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.SALES-L2.ITEMS',
    //                     link: '/sales/item',
    //                     parentId: 3,
    //                 },
    //                 {
    //                     id: 16,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.SALES-L2.FREEFORM',
    //                     link: '/sales/freeformdescription',
    //                     parentId: 3,
    //                 },
    //                 {
    //                     id: 17,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.SALES-L2.CHARGES',
    //                     link: '/sales/chargemaster',
    //                     parentId: 3,
    //                 },
    //                 {
    //                     id: 18,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.SALES-L2.BANK',
    //                     link: '/sales/bankmaster',
    //                     parentId: 3,
    //                 },
    //                 {
    //                     id: 19,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.SALES-L2.CREDITNOTE',
    //                     link: '/sales/creditnoteremark',
    //                     parentId: 3,
    //                 }
    //             ]
    //         },
    //         {
    //             id: 21,
    //             label: 'MENUITEMS.MASTERS.LIST.LEVEL1.CUSTOMERS',
    //             parentId: 2,
    //             subItems: [
    //                 {
    //                     id: 22,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.CUSTOMERS-L2.CUSTOMER',
    //                     link: '/customer/customer/add',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 23,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.CUSTOMERS-L2.CUSTOMERRATE',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 24,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.CUSTOMERS-L2.CONSIGNEE',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 25,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.CUSTOMERS-L2.SHIPPER',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 26,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.CUSTOMERS-L2.GROUP',
    //                     parentId: 21,
    //                 },
    //                 {
    //                     id: 27,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.CUSTOMERS-L2.PURCHASE',
    //                     parentId: 21,
    //                 }
    //             ]
    //         },
    //         {
    //             id: 28,
    //             label: 'MENUITEMS.MASTERS.LIST.LEVEL1.VENDORS',
    //             parentId: 2,
    //             subItems: [
    //                 {
    //                     id: 29,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.VENDORS-L2.VENDOR',
    //                     link:'vendors/vendors',
    //                     parentId: 28,
    //                 },
    //                 {
    //                     id: 30,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.VENDORS-L2.VENDORCONTRACT',
    //                     parentId: 28,
    //                 }
    //             ]
    //         },
    //         {
    //             id: 31,
    //             label: 'MENUITEMS.MASTERS.LIST.LEVEL1.OPERATIONS',
    //             parentId: 2,
    //             subItems: [
    //                 {
    //                     id: 32,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.OPERATIONS-L2.SERVICEMAPPING',
    //                     link:'operations/vendorservice',
    //                     parentId: 31,
    //                 },
    //                 {
    //                     id: 33,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.OPERATIONS-L2.FIELDEXECUTIVE',
    //                     parentId: 31,
    //                 },
    //                 {
    //                     id: 34,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.OPERATIONS-L2.PINCODE',
    //                     parentId: 31,
    //                 },
    //                 {
    //                     id: 35,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.OPERATIONS-L2.AREA',
    //                     parentId: 31,
    //                 },
    //                 {
    //                     id: 36,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.OPERATIONS-L2.EXCEPTION',
    //                     parentId: 31,
    //                 },
    //                 {
    //                     id: 37,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.OPERATIONS-L2.ZIPZONE',
    //                     parentId: 31,
    //                 },
    //                 {
    //                     id: 38,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.OPERATIONS-L2.HOLIDAYS',
    //                     parentId: 31,
    //                 },
    //                 {
    //                     id: 39,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.OPERATIONS-L2.VEHICLE',
    //                     parentId: 31,
    //                 },
    //                 {
    //                     id: 40,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.OPERATIONS-L2.DRIVERS',
    //                     parentId: 31,
    //                 },
    //                 {
    //                     id: 41,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.OPERATIONS-L2.VEHICLEOWNERS',
    //                     parentId: 31,
    //                 },
    //                 {
    //                     id: 42,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.OPERATIONS-L2.ODAPINCODE',
    //                     parentId: 31,
    //                 },
    //                 {
    //                     id: 43,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.OPERATIONS-L2.JOBASSIGN',
    //                     parentId: 31,
    //                 },
    //                 {
    //                     id: 44,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.OPERATIONS-L2.AIRLINE',
    //                     parentId: 31,
    //                 },
    //                 {
    //                     id: 45,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.OPERATIONS-L2.COUNTRYPINCODES',
    //                     parentId: 31,
    //                 },
    //                 {
    //                     id: 46,
    //                     label: 'MENUITEMS.MASTERS.LIST.LEVEL1.OPERATIONS-L2.OTHER',
    //                     parentId: 31,
    //                 }
    //             ]
    //         }
    //     ]
    // },


];
